/*
 * Third party (bower packages)
 */

// ../../bower_components/jquery/dist/jquery.js

/*
 * Third party  (loaded manually)
 */

// third-party/jquery.jscrollpane.min.js
// third-paråty/jquery.mousewheel.js
// third-party/infobubble.js
// third-party/nanoscroller.js

/*
 * Custom
 */

// JS здесь

/*------------------------------------
JQUERY READY
------------------------------------*/

jQuery( document ).ready( function( $ ) {
    
    var $html         = $( 'html' ),
        $body         = $( 'body' ),
        $window       = $( window ),
        mousewheelevt = (/Firefox/i.test(navigator.userAgent)) ? "DOMMouseScroll" : "mousewheel",
        toouch_scroll = 0,
        touch_coords  = { x: 0, y: 0 },
        ajax_info     = { url: '/wp-admin/admin-ajax.php' },
        template_url  = '/';
    
    /*------------------------------------
    NORMALIZE JS
    ------------------------------------*/
    
    $html.on( 'click', '[href^="#"]', function( event ) {
        event.preventDefault();
        
        var href = $( this ).attr( 'href' );
        
        if( '#' == href ) {
            return 0;
        }
        else {
            var target_offset = $( '[name="' + href.substr( 1 ) + '"]' ).offset();
            
            $body.animate(
                {
                    scrollTop: target_offset.top
                },
                Math.abs( $body.scrollTop() - target_offset.top ) / 2
            );
        }
    }); // $( '[href^="#"]' ).on( 'click', function( event ) { ... } )

    /*------------------------------------
    ADD FORM ERRORS
    ------------------------------------*/

    function add_form_errors( $form, errors ) {
        $form.addClass( 'errors' );
        
        for( var i = 0; i < errors.length; i++ ) {
            var error = errors[i];

            $form
                .find( '[name="' + error.field + '"], [for="' + error.field + '"]' )
                    .addClass( 'has-error' );
        }
    } // function add_form_errors( $form, errors ) { ... }
    
    /*------------------------------------
    REMOVE FORM ERRORS
    ------------------------------------*/

    function remove_form_errors( $form ) {
        $form.find( '.has-error' ).removeClass( 'has-error' );
    } // function remove_form_errors( $form ) { ... }
    
    $( document ).on( 'click focus', 'form .has-error', function() {
         var $input = $( this );
        
        $input.add( $input.closest( 'form' ).find( '[for="' + $input.attr( 'name' ) + '"]' ) ).removeClass( 'has-error' );
    });
    
    /*------------------------------------
    RESET FORMS
    ------------------------------------*/
    
    function reset_forms( $wrapper ) {
        var $form       = $wrapper.find( 'form' ),
            forms_count = $form.size();
        
        $form.removeClass( 'success errors' );
        
        remove_form_errors( $wrapper );
        
        if( forms_count ) {
            for( var i = 0; i < forms_count; i++ ) {
                $form.eq( i )[0].reset();
            }
            $form.find( '.has-checkbox' ).removeClass( 'checked' );
            $form.find( '.files-names' ).html( '' );
        }
    }
    
    /*------------------------------------
    REFRESHABLE ADD LOADER
    ------------------------------------*/
    
    $( '.refreshable' ).each( function( i, refreshable ) {
        $( refreshable ).find( '.loader-container' ).append( '<div class="loader"></div>' );
    });
    
    /*------------------------------------
    ATTACH FILES
    ------------------------------------*/
    
    $( document ).on( 'change', '[type="file"]', function() {
        var $input     = $( this ),
            $filename  = $input.closest( '.attach-files' ).find( '.files-names' ),
            filesnames = '';
        
        for( var i = 0, count = $input[0].files.length; i < count; i++ ) {
            filesnames += '<span class="filename">' + $input[0].files[i].name + '</span>';
        }
        
        $filename.html( filesnames );
    });
    
    /*------------------------------------
    SEND FORMS
    ------------------------------------*/
    
    $( document )
        .on( 'submit', '[data-form="email_form"]', function( event ) {
            event.preventDefault();
        
            var $form        = $( this ),
                $wrapper     = $form,
                form_data    = new FormData( $form[0] ),
                form_name    = $form.attr( 'name' ),
                $file_inputs = $form.find( '[type="file"]' );
            
            for( var i = 0, size = $file_inputs.size(); i < size; i++ ) {
                var $file_input = $file_inputs.eq( i ),
                    files_title = $file_input.attr( 'name' ),
                    files       = $file_input[0].files,
                    _files      = [];
                
                for( var f = 0, count = files.length; f < count; f++ ) {
                    form_data.append( files_title + '[]', files[ f ] );
                }
            }
        
            form_data.append( 'action', 'send_email_form' );
            form_data.append( 'form_name', form_name );
        
            $wrapper.addClass( 'refreshing' );
        
            $.ajax(
                {
                    type:        'POST',
                    url:         ajax_info.url,
                    data:        form_data,
                    dataType:    'JSON',
                    processData: false,
                    contentType: false,
                    success:
                        function( response ) {
                            if( 0 == response.status ) {
                                console.log( 'Сервер вернул пустой ответ' );
                            }
                            else if ( 'success' == response.status ) {
                                $form.addClass( 'success' );

                                reset_forms( $form );

                                setTimeout(
                                    function() {
                                        $form.removeClass( 'success' );
                                        reset_forms( $form.parent() );
                                    },
                                    3500
                                );
                            }
                            else if ( 'fail' == response.status ) {
                                add_form_errors( $form, response.data.errors );
                            }
                            else {
                                console.log( 'Неверный формат ответа от сервера' );
                            }
                        },
                    error:
                        function( jqXHR, textStatus, errorThrown ) {
                            console.log( textStatus + ': ' + errorThrown );
                        },
                    complete:
                        function() {
                            $wrapper.removeClass( 'refreshing' );
                        }
                }
            );
        
        });
    
    /*------------------------------------
    GET VACANCIES LIST
    ------------------------------------*/
    
    $( 'select#vacancyList' ).on( 'change', function() {
         var $select   = $( this ),
             $items    = $( '.vacancies__list' ),
             $wrapper  = $items.closest( '.refreshable' ),
             form_data = new FormData();
        
        $wrapper.addClass( 'refreshing' );
        
        form_data.append( 'action', 'get_vacancies_list' );
        form_data.append( 'vacancies_cat_id', $select.val() );
        
        $.ajax(
            {
                type       : 'POST',
                url        : ajax_info.url,
                data       : form_data,
                dataType   : 'JSON',
                processData: false,
                contentType: false,
                success:
                    function( response ) {
                        if( 0 == response.status ) {
                            console.log( 'Сервер вернул пустой ответ' );
                        }
                        else if ( 'success' == response.status ) {
                            $items.html( response.data.html );
                        }
                        else {
                            console.log( 'Неверный формат ответа от сервера' );
                        }
                    },
                error:
                    function( jqXHR, textStatus, errorThrown ) {
                        console.log( textStatus + ': ' + errorThrown );
                    },
                complete:
                    function() {
                        $wrapper.removeClass( 'refreshing' );
                    }
            }
        );
        
    });
    
}); // jQuery( document ).ready()
jQuery( document ).ready( function( $ ) {

    $('a[href^="#modal-"]').click(function () {
        var th      = $(this);
        var mid     = $(this).attr('href');

        if ($(this).hasClass("active")) {
            th.removeClass("active");
            $('.overlay').removeClass('active');
            $('#'.mid).removeClass('show');
            $('body').removeClass('noscroll');
        } else {
            th.addClass("active");
            $('body').addClass('noscroll');
            $('.overlay').addClass('active');
            $(mid).addClass('show');
        }
        return false;
    });

    $(".overlay .closebtn").click(function () {
        $('a[href^="#modal-"]').removeClass("active");
        $('.overlay').removeClass('active');
        $('.overlay .modal').removeClass('show');
        $('body').removeClass('noscroll');
        return false;
    });

});